import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Slider.scss';

const Slider = ({ min = 1, max, value, suffix = '', onChange }) => {
  let inputRef = useRef(null);

  const updateValue = () => {
    if (inputRef) {
      const nomrmalized = (inputRef.value - inputRef.min) / (inputRef.max - inputRef.min);
      inputRef.style.setProperty('--value', `${nomrmalized * 100}%`);
    }
  };

  const auxChange = (v) => {
    updateValue();
    onChange(v);
  };

  return (
    <div className="Slider">
      <span className="Slider-range">
        {min}
        {suffix}
      </span>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        className="Slider-slider"
        onChange={auxChange}
        ref={(ref) => {
          inputRef = ref;
          updateValue();
        }}
      />
      <span className={classnames('Slider-range', { 'Slider-range--right': !suffix })}>
        {max}
        {suffix}
      </span>
    </div>
  );
};

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  suffix: PropTypes.string,
  onChange: PropTypes.func,
};

export default Slider;
