import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './FaqHome.scss';
import FaqQuestion from './components/FaqQuestion';
import questions from './questions.json';

const FaqHome = ({ hash = typeof window !== `undefined` && window.location.hash.slice(1) }) => {
  const [openedId, setOpenedId] = useState(hash);

  useEffect(() => {
    setOpenedId(hash);
  }, [hash]);

  const handleQuestionClick = id => {
    if (id !== openedId) {
      setOpenedId(id);
      return;
    }
    setOpenedId('');
  };

  return (
    <div className="FaqHome">
      <h1 className="FaqHome-title">Preguntas frecuentes</h1>
      <ul className="FaqHome-list">
        {questions.map(question => (
          <li key={question.id} className="FaqHome-item" name={question.id} id={question.id}>
            <FaqQuestion
              question={question}
              onClick={handleQuestionClick}
              isOpened={question.id === openedId}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

FaqHome.propTypes = {
  hash: PropTypes.string,
};

export default FaqHome;
