export const promoTypes = {
  RATE_DISCOUNT: 'rate_discount',
  FIX_DISCOUNT: 'fix_discount',
};
export const errorCodes = {
  INVALID: 'INVALID',
  ONLY_RETURNING: 'ONLY_RETURNING',
  ONLY_NEW: 'ONLY_NEW',
  ALREADY_USED: 'ALREADY_USED',
};
