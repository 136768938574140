import person1 from '../../skin/images/landing/person1.jpg';
import person2 from '../../skin/images/landing/person2.jpg';
import person3 from '../../skin/images/landing/person3.jpg';
import person4 from '../../skin/images/landing/person4.jpg';
import person5 from '../../skin/images/landing/person5.jpg';

export const services = [
  {
    image: person1,
    title: 'Micro préstamos online',
    description:
      'Pedir un micro préstamo online es de lo más simple y cualquier persona, se encuentre en la situación que se encuentre, lo puede solicitar.',
  },
  {
    image: person2,
    title: 'Préstamo en 10 minutos',
    description:
      '¿Recibir el dinero de un crédito en tan solo 10 minutos? Con QuéBueno es una realidad. Solicita tu crédito y recibe el dinero de forma casi inmediata.',
  },
  {
    image: person3,
    title: 'Mini créditos sin papeleos',
    description:
      'En QuéBueno puedes solicitar tu minicrédito sin necesidad de presentar una nómina o un aval. Nunca antes había sido tan fácil y rápido conseguir financiación.',
  },
  {
    image: person4,
    title: 'Minicréditos sin Nómina',
    description:
      'Consigue minicréditos de hasta 900€ sin necesidad de presentar una nómina o un aval. Te sorprenderá lo rápido que es el proceso para conseguir uno de nuestros créditos.',
  },
  {
    image: person5,
    title: 'Créditos Rápidos online',
    description:
      'Obtén dinero de forma inmediata con nuestros créditos rápidos online. Descubre como solicitar el tuyo y completa tu solicitud en unos pocos minutos.',
  },
];
