import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { setCookie, removeCookie } from '../../shared/libs/cookies';
import * as routes from '../../shared/routes';
import { round2Dec } from '../../shared/libs/maths';
import {
  addDaysToToday,
  formatDateLong,
  getDayOfMonth,
  getTotalMonthDays,
  add1Month,
} from '../../shared/libs/dateTime';
import SliderInput from './components/SliderInput';
import PromoCode from './components/PromoCode';
import './Calculator.scss';
import { useDueDateExperiment } from '../../shared/customHooks';
import config from '../../shared/config';
import DiscountPopup from '../Discount/DiscountPopup';

const Calculator = ({
  initialAmount = config.initialAmount,
  initialDays,
  minLoanAmount = config.minLoanAmount,
  maxAmount = config.maxLoanAmount,
  maxDaysConfig = config.maxDays,
  interestRate = config.interestRate,
  isReturningUser = false,
  token,
  zeroInterestsForNewUsersFlag,
  onRecalculateData = () => {},
  handleDiscount = () => {},
}) => {
  const [amount, setAmount] = useState(initialAmount);
  const [days, setDays] = useState(0);
  const [maxDays, setMaxDays] = useState(maxDaysConfig);
  const [interests, setInterests] = useState(0);
  const [total, setTotal] = useState(0);
  const [dueDate, setDueDate] = useState(new Date(Date.now()));
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [minAmount, setMinAmount] = useState(minLoanAmount);
  const [discountPopupVisible, setDiscountPopupVisible] = useState(false);
  const { offset: offsetMaxDays, enabled: offsetMaxDaysEnabled } = useDueDateExperiment();

  // const handleDiscountShow = () => {
  //   setDiscountPopupVisible(true);
  // };

  const handleClick = () => {
    setCookie('amount', amount);
    setCookie('days', days);

    if (isReturningUser) {
      window.location.href = routes.CBO_LOAN_APPLICATION;
    } else if (token) {
      window.location.href = `${routes.LOGIN}/${token}`;
      setCookie('cta', true);
    } else {
      window.location.href = routes.LOAN_APPLICATION;
    }
  };

  const handleApplyPromo = (discount, minAmountToSet) => {
    setPromoDiscount(discount);
    if (minAmountToSet > minLoanAmount) {
      setMinAmount(minAmountToSet);
      setAmount(Math.max(amount, minAmountToSet));
    }
  };

  useEffect(() => {
    if (offsetMaxDaysEnabled) {
      const currentDate = new Date(Date.now());
      const remainingDays = getTotalMonthDays(currentDate) - getDayOfMonth();
      if (getDayOfMonth() <= 19) {
        setMaxDays(30);
      } else {
        const remainingNextMonth =
          remainingDays + getTotalMonthDays(add1Month(currentDate)) + offsetMaxDays;
        setMaxDays(remainingNextMonth > 30 ? remainingNextMonth : 30);
      }
      removeCookie('cta');
    }
  }, [offsetMaxDays, offsetMaxDaysEnabled]);

  useEffect(() => {
    setAmount(initialAmount);
  }, [initialAmount]);

  useEffect(() => {
    setDays(initialDays || maxDays);
  }, [initialDays, maxDays]);

  useEffect(() => {
    const calculateInterests = () =>
      zeroInterestsForNewUsersFlag && !isReturningUser
        ? 0
        : round2Dec(amount * days * interestRate);
    const intrst = calculateInterests();
    setInterests(intrst);
  }, [days, amount, zeroInterestsForNewUsersFlag, isReturningUser]);

  useEffect(() => {
    const calculateTotal = () => round2Dec(+amount + interests);
    const total = calculateTotal();
    setTotal(total);

    const tae = zeroInterestsForNewUsersFlag
      ? 0
      : (((total / +amount) * (365 / +days) - 1) * 100).toFixed(2);
    onRecalculateData({ amount, days, amountInterests: +amount + interests, tae });
  }, [amount, interests, zeroInterestsForNewUsersFlag]);

  useEffect(() => {
    setDueDate(addDaysToToday(days));
  }, [days]);

  const is60DaysLanding = () => maxDays >= 60;

  return (
    <>
      <DiscountPopup visible={discountPopupVisible} setVisible={setDiscountPopupVisible} />
      <div className="Calculator">
        <div className="Calculator-top">
          <SliderInput
            min={minAmount}
            max={maxAmount}
            value={amount}
            name="amount"
            suffix="€"
            maxLength={3}
            onChange={(val) => setAmount(val)}
            text="¿Cuánto dinero necesitas?"
          />
          {!is60DaysLanding() && (
            <SliderInput
              min={config.minDays}
              max={maxDays}
              value={days}
              name="days"
              suffix="d"
              maxLength={2}
              onChange={(val) => setDays(val)}
              text="¿En cuántos días lo quieres devolver?"
            />
          )}
          {is60DaysLanding() && (
            <div className="Calculator-expiration u-margin-top">
              <span>Duración total del préstamo:</span>
              <span>{`${maxDays} días`}</span>
            </div>
          )}
          {!is60DaysLanding() && (
            <div className="Calculator-expiration u-margin-top">
              <span>Fecha de devolución:</span>
              <span>{formatDateLong(dueDate)}</span>
            </div>
          )}
          <Button
            className="Calculator-cta"
            name="apply"
            size="large"
            onClick={handleClick}
            primary
            block
          >
            Solicitar préstamo
          </Button>
          <Button className="Calculator-discount" onClick={handleDiscount} primary link>
            Consigue aquí un 5% de descuento en tu préstamo
          </Button>
        </div>
        <div className="Calculator-bottom">
          <div className="Calculator-interest u-margin-top">
            <span>
              <span className="bold"> Intereses: </span>
              {!isReturningUser && zeroInterestsForNewUsersFlag && (
                <span className="Calculator-tae">(TAE 0%)</span>
              )}
            </span>
            <span>
              {promoDiscount > 0 && <span className="Calculator-crossedOut">{interests}€</span>}
              {round2Dec(interests - promoDiscount)}€
            </span>
          </div>
          <div className="Calculator-separator" />
          <div className="Calculator-total">
            <span className="bold">TOTAL A DEVOLVER:</span>
            <span>
              {promoDiscount > 0 && <span className="Calculator-crossedOut">{total}€</span>}
              {round2Dec(total - promoDiscount)}€
            </span>
          </div>
          <div className="Calculator-promo">
            <PromoCode
              linkText="¿Tienes un código promocional?"
              interests={interests}
              onApplyPromo={handleApplyPromo}
            />
          </div>
        </div>
      </div>
    </>
  );
};

Calculator.propTypes = {
  initialAmount: PropTypes.number,
  initialDays: PropTypes.number,
  maxAmount: PropTypes.number,
  interestRate: PropTypes.number,
  isReturningUser: PropTypes.bool,
  token: PropTypes.string,
  zeroInterestsForNewUsersFlag: PropTypes.bool,
};

export default Calculator;
