import Axios from 'axios';
import compose from 'lodash/fp/compose';
import { getCookie } from './cookies';
import { addErrorCatching } from './interceptors';

const jwt = getCookie('jwt');

const httpClient = Axios.create({
  headers: {
    Accept: 'application/vnd.quebueno.v3',
    ...(jwt && { Authorization: `Bearer ${jwt}` }),
  },
  timeout: 5000,
  validateStatus: status => status >= 200 && status <= 500,
});

export default compose(addErrorCatching)(httpClient);
