import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from 'react-slick';
import Button from '../Button';
import * as routes from '../../shared/routes';
import { services } from './OurServices.constants';
import SliderArrow from '../SliderArrow';
import './OurServices.scss';

const OurServices = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    swipeToSlide: true,
    prevArrow: <SliderArrow to="prev" />,
    nextArrow: <SliderArrow to="next" />,
  };

  return (
    <div className="OurServices">
      <Slider {...sliderSettings}>
        {services.map(({ image, title, description }) => (
          <div key={title} className="OurServices-element">
            <div className="OurServices-top">
              <LazyLoadImage wrapperClassName="OurServices-image" src={image} alt="Persona" />
            </div>
            <div className="OurServices-content">
              <h2 className="OurServices-sectionName">NUESTROS SERVICIOS</h2>
              <h1 className="OurServices-title">{title}</h1>
              <p className="OurServices-text u-margin-top">{description}</p>
              <Button
                className="OurServices-button"
                name="apply"
                size="large"
                onClick={() => {
                  window.location.href = routes.LOAN_APPLICATION;
                }}
                block-sm
                primary
              >
                Solicitar ahora
              </Button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OurServices;
