import React from 'react';
import PropTypes from 'prop-types';
import CustomerOpinion from '../CustomerOpinion';
import HowDoesItWork from '../HowDoesItWork';
import CalculatorSection from '../CalculatorSection';
import Press from '../Press';
import OurServices from '../OurServices';
import FaqHome from '../FaqHome';
import FastCredits from '../FastCredits';
import './Home.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Home = ({ user, details, openModal, onRecalculateData, handleDiscount }) => (
  <>
    <section className="Home-calculatorSection">
      <CalculatorSection
        user={user}
        details={details}
        openModal={openModal}
        onRecalculateData={onRecalculateData}
        handleDiscount={handleDiscount}
      />
    </section>
    <section className="Home-howWorksSection">
      <HowDoesItWork />
    </section>
    <section className="Home-fastCredits">
      <FastCredits />
    </section>
    <section className="Home-press">
      <Press />
    </section>
    <section className="Home-ourServicesSection">
      <OurServices />
    </section>
    <section className="Home-customerOpinionSection">
      <CustomerOpinion />
    </section>
    <section className="Home-faqSection">
      <FaqHome />
    </section>
  </>
);

Home.propTypes = {
  user: PropTypes.object,
  openModal: PropTypes.func,
  onRecalculateData: PropTypes.func,
  details: PropTypes.shape({
    title: PropTypes.node,
    image: PropTypes.string,
  }),
  handleDiscount: PropTypes.func,
};

export default Home;
