import PropTypes from 'prop-types';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './DiscountQuestions.scss';
import Button from '../../Button';
import { setCookie } from '../../../shared/libs/cookies';

const QUESTIONS = [
  { id: 'where_did_you_find_us', question: '¿Dónde nos conociste?' },
  { id: 'why_do_you_need_the_money', question: '¿Para qué necesitas el dinero?' },
  { id: 'what_would_you_improve', question: '¿Qué mejorarías de nuestra página?' },
];

const DiscountQuestions = ({ handleSubmit }) => {
  const questionSchema = Yup.object().shape({
    question: Yup.string().required('Campo requerido'),
    answer: Yup.string().required('Campo requerido'),
  });

  const validationSchema = Yup.object({
    questions: Yup.array()
      .of(questionSchema)
      .min(3, 'Tienes que responder todas las preguntas')
      .required('Campo requerido'),
  });

  const formik = useFormik({
    initialValues: {
      questions: QUESTIONS.map((q) => ({ ...q, answer: '' })),
    },
    validationSchema,
    onSubmit: (values) => {
      setCookie('discount-questions', JSON.stringify(values.questions));
      handleSubmit();
    },
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    formik?.handleSubmit();
  };

  const hasError = (field, idx) =>
    (formik?.errors?.questions &&
      formik?.errors?.questions[idx] &&
      formik?.errors?.questions[idx][field]) ||
    '';
  return (
    <div className="DiscountQuestions">
      <form noValidate onSubmit={handleFormSubmit}>
        {formik.values.questions.map((q, idx) => {
          return (
            <div key={idx}>
              <div className="DiscountQuestions-question">
                <p>{q?.question}</p>
                <p className="DiscountQuestions-error">{hasError('answer', idx)}</p>
              </div>
              <textarea
                value={q?.answer}
                name={`questions[${idx}].answer`}
                onChange={formik.handleChange}
              />
            </div>
          );
        })}
        <div className="DiscountQuestions-button">
          <Button primary submit>
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
};

DiscountQuestions.defaultProps = {};

DiscountQuestions.propTypes = {
  handleSubmit: PropTypes.func,
};

export default DiscountQuestions;
