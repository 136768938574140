import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../../../Button';
import { ArrowDownIcon, ArrowUpIcon, PlusIcon, MinusIcon } from '../../../../skin';
import './ExpansionPanel.scss';

const iconTypes = { ARROW: 'Arrow', ARITHMETIC: 'Arithmetic' };
const iconPositions = { LEFT: 'Left', RIGHT: 'Right' };
const { ARROW, ARITHMETIC } = iconTypes;
const { LEFT, RIGHT } = iconPositions;

export default function ExpansionPanel({
  children,
  title,
  className,
  openingMsg,
  closingMsg,
  iconType,
  iconPosition,
  customTextClass,
  customContentClass,
  opened,
  onClick,
}) {
  const openingIcon =
    iconType === ARROW ? (
      <ArrowDownIcon className="ExpansionPanel-icon IconMainColor" />
    ) : (
      <span className="ExpansionPanel-icon">
        <PlusIcon className="IconMainColor" />
      </span>
    );

  const closingIcon =
    iconType === ARROW ? (
      <ArrowUpIcon className="ExpansionPanel-icon IconMainColor" />
    ) : (
      <span className="ExpansionPanel-icon">
        <MinusIcon className="IconMainColor" />
      </span>
    );

  return (
    <div className={classnames('ExpansionPanel', className)}>
      <p className="ExpansionPanel-title">
        {title}
        <Button
          link
          className={classnames('ExpansionPanel-action', customTextClass, {
            iconRight: iconPosition === RIGHT,
          })}
          onClick={onClick}
        >
          <span className={`ExpansionPanel-icon${iconPosition}`}>
            {opened ? closingIcon : openingIcon}
          </span>
          <span>{opened ? closingMsg : openingMsg}</span>
        </Button>
      </p>
      {opened && (
        <div className={classnames('ExpansionPanel-content', customContentClass)}>{children}</div>
      )}
    </div>
  );
}

ExpansionPanel.defaultProps = {
  iconType: ARROW,
  iconPosition: LEFT,
  opened: false,
};

ExpansionPanel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  openingMsg: PropTypes.string,
  closingMsg: PropTypes.string,
  iconType: PropTypes.oneOf([ARROW, ARITHMETIC]),
  iconPosition: PropTypes.oneOf([LEFT, RIGHT]),
  customTextClass: PropTypes.string,
  customContentClass: PropTypes.string,
  opened: PropTypes.bool,
  onClick: PropTypes.func,
};
