import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import img from './images/side_image.png';
import './FastCredits.scss';

const FastCredits = () => (
  <div className="FastCredits Grid">
    <div className="Grid-cell u-lg-size1of2">
      <LazyLoadImage
        wrapperClassName="FastCredits-img"
        src={img}
        alt="Chica sonriendo"
        loading="lazy"
      />
    </div>
    <div className="FastCredits-content Grid-cell u-lg-size1of2">
      <h1 className="FastCredits-title">Créditos rápidos ¡QuéBueno!</h1>
      <p className="FastCredits-subTitle">
        Los créditos rápidos QuéBueno son una manera fácil y rápida de conseguir dinero tanto para
        emergencias como para un uso esporádico en actividades de ocio.
      </p>
      <p className="Text FastCredits-text">
        Solicitando un minicrédito recibes una cantidad de dinero, pudiendo obtener hasta 900 euros,
        para utilizarlos como prefieras. En QuéBueno no necesitas justificar para qué solicitas el
        dinero. El préstamo recibido puedes devolverlo hasta 30 días después de la concesión del
        mismo. Así que cuentas con el suficiente tiempo para poder reunir el dinero con facilidad.
      </p>
      <p className="Text">
        <strong>¿Cómo solicitar?</strong>
      </p>
      <p className="Text FastCredits-text">
        Para solicitar uno de nuestros préstamos rápidos lo único que necesitas hacer es acceder a
        nuestra página web. Debes seleccionar en nuestro simulador de créditos la cantidad de dinero
        urgente y el tiempo que necesitas. Seguidamente pasarás a rellenar el formulario de
        solicitud para que podamos verificar tu identidad. Finalmente te avisaremos con un correo
        electrónico si tu préstamo ha sido concedido. Si es así, recibirás el dinero en tu cuenta en
        tan solo 10 minutos.
      </p>
    </div>
  </div>
);

export default FastCredits;
