import React from 'react';
import { pure } from 'recompose';
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { opinions } from './CustomerOpinion.constants';
import Opinion from './components/Opinion';
import ekomi from '../../skin/images/landing/ekomi.png';
import './CustomerOpinion.scss';
import useEkomiRating from '../../shared/customHooks/ekomiRating';

const CustomerOpinion = () => {
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  const ekomiRating = useEkomiRating();

  return (
    <div className="CustomerOpinion">
      <h1 className="CustomerOpinion-title">Opiniones de nuestros clientes</h1>
      <h2 className="CustomerOpinion-subTitle">
        El 94% de nuestros usuarios se sienten totalmente satisfechos con nuestros productos
      </h2>
      <div className="CustomerOpinion-opinion">
        <Slider {...sliderSettings}>
          {opinions.map(({ date, text, rate }) => (
            <Opinion key={date} date={date} text={text} rate={rate} />
          ))}
        </Slider>
      </div>
      <div className="CustomerOpinion-results">
        <LazyLoadImage wrapperClassName="CustomerOpinion-ekomiLogo" src={ekomi} alt="Ekomi" />
        <div className="CustomerOpinion-ekomi">
          <strong>{ekomiRating.averageRate}/10</strong>
          <p>Calculado sobre {ekomiRating.numberOfRatings} valoraciones</p>
          <a className="Link" href="https://www.ekomi.es/testimonios-quebueno.html">
            Ver opiniones de QuéBueno
          </a>
        </div>
      </div>
    </div>
  );
};

export default pure(CustomerOpinion);
