import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '../ExpansionPanel';
import './FaqQuestion.scss';

const FaqQuestion = ({ question: { question, answer, id }, isOpened, onClick }) => (
  <ExpansionPanel
    openingMsg={question}
    closingMsg={question}
    iconType="Arithmetic"
    iconPosition="Right"
    customTextClass="FaqQuestion-text"
    customContentClass="FaqQuestion-content"
    opened={isOpened}
    onClick={() => onClick(id)}
  >
    {/* eslint-disable-next-line */}
    <div className="FaqQuestion-listbullet" dangerouslySetInnerHTML={{ __html: answer }} />
  </ExpansionPanel>
);

FaqQuestion.propTypes = {
  question: PropTypes.object,
  onClick: PropTypes.func,
  isOpened: PropTypes.bool,
};

export default FaqQuestion;
