import { useEffect } from 'react';
import config from '../config';
import { getCurrentDateISOWithZone } from '../libs/dateTime';

const DEBUG_GTM = config.nodeEnv === 'development';
const debug = (msg, obj = {}) =>
  DEBUG_GTM && console.log(`%c ${msg}`, 'background: #117575; color: #fff', obj);

export default function useGTM({ pageTitle, pageContent, viewContent, slug }) {
  useEffect(() => {
    const { dataLayer } = window;
    const baseParams = {
      av: process.env.VERSION,
      en: config.nodeEnv,
      la: 'es',
      ts: getCurrentDateISOWithZone(),
      tm: Date.now(),
    };

    const dataToPush = {
      ...baseParams,
      pt: pageTitle,
      pc: pageContent,
      vc: viewContent,
      sl: slug,
    };

    if (dataLayer) {
      debug('[GTM] Pushing PageView to DataLayer', dataToPush);
      dataLayer.push(dataToPush);
    }
  }, []);
}