import React from 'react';
import { pure } from 'recompose';
import Slider from 'react-slick';
import Button from '../Button';
import { steps } from './HowDoesItWork.constants';
import Step from '../Step';
import './HowDoesItWork.scss';

const HowDoesItWork = () => {
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="HowDoesItWork">
      <div className="HowDoesItWork-steps">
        <Slider {...sliderSettings}>
          {steps.map(({ icon, title, desc }, index) => (
            <Step
              key={title}
              Icon={icon}
              title={title}
              desc={desc}
              showArrow={steps.length - 1 !== index}
            />
          ))}
        </Slider>
      </div>
      <a href="#calculator">
        <Button
          className="HowDoesItWork-button u-margin-top"
          name="apply"
          size="large"
          primary
          block
        >
          Solicitar préstamo
        </Button>
      </a>
    </div>
  );
};

export default pure(HowDoesItWork);
