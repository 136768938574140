import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as apiService from '../api.service';
import { getCookie, setCookie } from '../libs/cookies';

const cookieName = 'uuid';

export const useExperiments = () => {
  const [experiments, setExperiments] = useState({});

  useEffect(() => {
    const startExperiments = async () => {
      const uuid = getUniqueUserId();

      const { data } = await apiService.startExperiments(uuid);
      if (data) {
        setExperiments({ dueDate: data.due_date });
      }
    };
    startExperiments();
  }, []);

  return experiments;
}

const getUniqueUserId = () => {
  let userId = getCookie(cookieName);

  if (!userId) {
    userId = uuidv4();
    setCookie(cookieName, userId);
  }
  return userId;
};
