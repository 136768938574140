import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Calculator from '../Calculator';
import person from '../../skin/images/landing/person.png';
import * as apiService from '../../shared/api.service';
import './CalculatorSection.scss';

const CalculatorSection = ({ user, details, openModal, onRecalculateData, handleDiscount }) => {
  const [zeroInterestsForNewUsersFlag, setZeroInterestsForNewUsersFlag] = useState(null);
  const { t: token } = typeof window !== `undefined` && queryString.parse(window.location.search);

  useEffect(() => {
    const getZeroIntestsForNewUsersFlag = async () => {
      const { data, status } = await apiService.getZeroInterestsForNewUsersFlag();
      if (status === 200) {
        setZeroInterestsForNewUsersFlag(data.value);
      }
    };
    getZeroIntestsForNewUsersFlag();
  }, []);

  return (
    <div className="CalculatorSection Grid">
      <div className="CalculatorSection-container">
        <div className="Grid-cell CalculatorSection-leftSide">
          <h2 className="CalculatorSection-titleRow">{details.title}</h2>
          {zeroInterestsForNewUsersFlag && user && !user.returning && (
            <div className="CalculatorSection-titleRow CalculatorSection-subTitle">
              <span className="CalculatorSection-hugeText">0%</span>
              <span>INTERESES</span>
            </div>
          )}
          <LazyLoadImage
            wrapperClassName="CalculatorSection-person"
            src={details.image}
            alt="person image"
          />
        </div>
        <div className="Grid-cell CalculatorSection-rightSide">
          <div className="">
            <div id="calculator" className="CalculatorSection-calculatorWrapper">
              <Calculator
                maxAmount={user.max_amount}
                interestRate={user.interest_rate}
                initialAmount={user && user.max_amount}
                isReturningUser={user.returning}
                token={token}
                zeroInterestsForNewUsersFlag={zeroInterestsForNewUsersFlag}
                onRecalculateData={onRecalculateData}
                handleDiscount={handleDiscount}
              />
            </div>
            <div className="CalculatorSection-extraInfo">
              <div className="CalculatorSection-infoLinks">
                <a className="CalculatorSection-infoLink" onClick={openModal}>
                  INFORMACIÓN SOBRE EL PRÉSTAMO
                </a>
                {/* <a className="CalculatorSection-infoLink" href="#devolucion">
                  DEVOLUCIÓN
                </a>
                <a className="CalculatorSection-infoLink" href="#prorroga">
                  PRÓRROGA
                </a>
                <a className="CalculatorSection-infoLink" href="#deuda">
                  POSICIONES DEUDORAS VENCIDAS
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CalculatorSection.defaultProps = {
  details: {
    title: (
      <span>
        PIDE TU PRÉSTAMO AHORA Y RECÍBELO EN{' '}
        <span className="CalculatorSection-semibold">10 MINUTOS</span>
      </span>
    ),
    image: person,
  },
};

CalculatorSection.propTypes = {
  user: PropTypes.object,
  details: PropTypes.shape({
    title: PropTypes.node,
    image: PropTypes.string,
  }),
  openModal: PropTypes.func,
  onRecalculateData: PropTypes.func,
  handleDiscount: PropTypes.func,
};

export default CalculatorSection;
