import {
  format,
  differenceInCalendarDays,
  isAfter,
  addDays,
  getDate,
  getDaysInMonth,
  addMonths,
} from 'date-fns';
import { es } from 'date-fns/locale';

export const formatDate = date => format(new Date(date), 'dd/MM/yyyy');
export const formatDateLong = date => format(date, "EEE, d 'de' MMMM 'de' yyyy", { locale: es });
export const differenceInDaysToToday = date =>
  Math.abs(differenceInCalendarDays(new Date(date), new Date(Date.now())));
export const isAfterToday = date => isAfter(new Date(date), new Date(Date.now()));
export const addDaysToToday = days => addDays(new Date(Date.now()), days);
export const getDayOfMonth = () => getDate(new Date(Date.now()));
export const getTotalMonthDays = date => getDaysInMonth(new Date(date));
export const add1Month = date => addMonths(new Date(date), 1);
/**
 * Gets a date Time with the following format:  2018-10-09T17:44:41+02:00
 */
export const getCurrentDateISOWithZone = () => {
  const date = new Date(Date.now());
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = num => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  };
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
};
