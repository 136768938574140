import React from 'react';
import { pure } from 'recompose';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import abc from './images/logo-abc.jpg';
import elEconomista from './images/logo-eleconomista.jpg';
import elPais from './images/logo-elpais.jpg';
import expansion from './images/logo-expansion.jpg';
import laVanguardia from './images/logo-lavanguardia.jpg';
import tv3 from './images/logo-tv3.jpg';
import './Press.scss';

const Press = () => (
  <div className="Press">
    <h1 className="Press-title">Hablan de nosotros en:</h1>
    <div className="Press-content">
      <LazyLoadImage wrapperClassName="Press-logo" src={abc} alt="ABC" />
      <LazyLoadImage wrapperClassName="Press-logo" src={elEconomista} alt="El Economista" />
      <LazyLoadImage wrapperClassName="Press-logo" src={elPais} alt="El País" />
      <LazyLoadImage wrapperClassName="Press-logo" src={expansion} alt="Expansión" />
      <LazyLoadImage wrapperClassName="Press-logo" src={laVanguardia} alt="La Vanguardia" />
      <LazyLoadImage wrapperClassName="Press-logo" src={tv3} alt="TV3" />
    </div>
  </div>
);

export default pure(Press);
