import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';
import { QuoteIcon } from '../../../skin';
import './Opinion.scss';

const Opinion = ({ date, text, rate }) => {
  const renderStars = () => {
    let stars = '';
    for (let i = 0; i < 5; i++) {
      stars = i < rate ? `${stars}★` : `${stars}☆`;
    }
    return stars;
  };

  return (
    <div className="Opinion">
      <QuoteIcon className="Opinion-icon IconMainColor" />
      <p className="Opinion-date">{date}</p>
      <span className="Opinion-star">{renderStars()}</span>
      <p className="Opinion-text">{text}</p>
      <div className="Opinion-line" />
    </div>
  );
};

Opinion.propTypes = {
  date: PropTypes.string,
  text: PropTypes.string,
  rate: PropTypes.number,
};

export default pure(Opinion);
