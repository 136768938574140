export const opinions = [
  {
    date: 'del 10.12.2018 a las 11:51h',
    text:
      'Rapidez y eficacia. Muy buen servicio de soporte, por parte de las personas que atienden, amables y efectivas.',
    rate: 4,
  },
  {
    date: 'del 17.09.2019 a las 13:40h',
    text:
      '¡Excelente!. Muy transparente, fácil y sin muchos requisitos para ayudarte a llegar a fin de mes, el pago de alguna factura o para cualquier otro pequeño apoyo económico que nos pueda hacer falta. ¡Yo no elijo otro!.',
    rate: 5,
  },
  {
    date: 'del 09.09.2019 a las 11:40h',
    text: 'Servicio rápido y eficaz',
    rate: 4,
  },
  {
    date: 'del 08.09.2019 a las 13:52h',
    text:
      'Como siempre, todo perfecto. Fácil, rápido, comisiones bajas... La mejor plataforma de microprestamos para mi. Muchas gracias por todo.',
    rate: 5,
  },
  {
    date: 'del 02.09.2019 a las 09:33h',
    text:
      'En mi opinión el servicio que ofrecen es perfecto. Todo es rápido y seguro, en lo que a mi respecta el dinero solicitado lo tengo en la cuenta a los pocos segundos, inmejorable.',
    rate: 5,
  },
];
