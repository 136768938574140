import React from 'react';
import PropTypes from 'prop-types';
import Slider from '../Slider';
import './SliderInput.scss';

export const SliderInput = ({ min, max, value, name, suffix, onChange, text, maxLength }) => {
  const handleInputBlur = (event) => {
    const enteredValue = parseInt(event.target.value, 10);
    if (enteredValue < min) {
      onChange(min);
    } else if (enteredValue > max || !enteredValue) {
      onChange(max);
    } else {
      onChange(enteredValue);
    }
  };

  const validateChange = (event) => {
    if (event.target.value.length <= maxLength) {
      handleChange(event);
    }
  };

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const suffixText = () => {
    if (suffix === 'd') {
      return value === '1' ? 'día' : 'días';
    }
    return suffix;
  };

  return (
    <div className="SliderInput">
      <div className="SliderInput-top">
        <span>{text}</span>
        <div className={'SliderInput-aux'}>
          <span className="SliderInput-input-wrap">
            <span className="SliderInput-width-machine" aria-hidden="true">
              {value}
            </span>
            <input
              data-testid={`${name}-input`}
              type="number"
              tabIndex="0"
              name={name}
              className="SliderInput-input"
              value={value}
              onChange={validateChange}
              onBlur={handleInputBlur}
              maxLength={maxLength}
              step="1"
            />
          </span>

          <p>{suffixText()}</p>
        </div>
      </div>
      <Slider min={min} max={max} value={value} suffix={suffix} onChange={handleChange} />
    </div>
  );
};

SliderInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string,
  suffix: PropTypes.string,
  onChange: PropTypes.func,
  text: PropTypes.string,
  maxLength: PropTypes.number,
};

export default SliderInput;
