import { Step1Icon, Step2Icon, Step3Icon, Step4Icon } from '../../skin';

export const steps = [
  {
    icon: Step1Icon,
    title: 'Calcula tu crédito',
    desc:
      'Selecciona a través de nuestro simulador de crédito el dinero que necesitas y el tiempo en el que quieres devolverlo',
  },
  {
    icon: Step2Icon,
    title: 'Rellena la solicitud',
    desc:
      'Completa el formulario de solicitud para tu microcrédito sin presentar ningún tipo de papeleo y en pocos minutos',
  },
  {
    icon: Step3Icon,
    title: 'Evaluación rápida',
    desc:
      'Realizamos la evaluación exhaustiva de tu solicitud y en 30 segundos te informamos de la concesión de tu préstamo rápido',
  },
  {
    icon: Step4Icon,
    title: 'Tu dinero al instante',
    desc:
      'En aproximadamente 10 minutos tienes el dinero de tu crédito en tu cuenta bancaria. Para que lo puedas disfrutar al instante',
  },
];
