import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import queryString from 'query-string';
import Button from '../../../Button';
import { getCookie, removeCookie, setCookie } from '../../../../shared/libs/cookies';
import { CancelIcon } from '../../../../skin';
import * as apiService from '../../../../shared/api.service';
import { round2Dec } from '../../../../shared/libs/maths';
import { promoTypes, errorCodes } from './PromoCode.constants';
import './PromoCode.scss';

/* eslint-disable jsx-a11y/label-has-associated-control */
const PromoCode = ({ linkText, interests, onApplyPromo }) => {
  const [opened, setOpened] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [promo, setPromo] = useState({ type: '', amount: 0 });
  const [discount, setDiscount] = useState(0);
  const [minAmount, setMinAmount] = useState(1);
  const [firstApplyOfPromoCode, setFirstApplyOfPromoCode] = useState(true);

  useEffect(() => {
    const cookie = getCookie('popup_promo_code');
    if (cookie && promoCode !== cookie) {
      handleValidate(cookie).then(() => {
        removeCookie('popup_promo_code');
      });
    }
  }, [getCookie('popup_promo_code')]);

  const calcDiscount = (promoType, interest, amount) => {
    if (promoType === promoTypes.RATE_DISCOUNT) {
      setDiscount(round2Dec((interest * amount) / 100));
    } else if (promoType === promoTypes.FIX_DISCOUNT && interests >= amount) {
      setDiscount(amount);
    } else if (promoType === promoTypes.FIX_DISCOUNT && interests < amount) {
      setDiscount(interests);
    }
  };

  const handleValidate = async (code) => {
    if (code) {
      const {
        status,
        error,
        data: {
          valid,
          promo_type: promoType,
          promo: amount,
          error_code: errorCode,
          minimum_amount: minimumAmount,
        } = {},
      } = getCookie('jwt')
        ? await apiService.applyPromoCode(code)
        : await apiService.validatePromoCode(code);

      if (error || status === 406) {
        if (errorCode === errorCodes.ONLY_RETURNING) {
          setErrorMsg('Código solo válido para clientes');
        } else if (errorCode === errorCodes.ONLY_NEW) {
          setErrorMsg('Código solo válido para nuevos clientes');
        } else if (errorCode === errorCodes.ALREADY_USED) {
          setErrorMsg('Este código ya ha sido utilizado anteriormente');
        } else {
          setErrorMsg('Código no válido');
        }
        return;
      }

      if (valid) {
        setCookie('promo_code', code);
        setPromo({ type: promoType, amount });
        setMinAmount(minimumAmount);
        calcDiscount(promoType, interests, amount);
        setOpened(false);
      }
      setErrorMsg('');
    }
  };

  useEffect(() => {
    if (firstApplyOfPromoCode && interests > 0) {
      const { promo: parsedPromoCode } = queryString.parse(window.location.search);
      setPromoCode(parsedPromoCode);
      handleValidate(parsedPromoCode);
      setFirstApplyOfPromoCode(false);
    }
  }, [interests]);

  const handleOpen = () => {
    if (!opened && discount > 0) {
      setPromoCode('');
      setPromo({ type: '', amount: 0 });
      setDiscount(0);
      setMinAmount(1);
    }
    setOpened(!opened);
  };

  useEffect(() => {
    onApplyPromo(discount, minAmount);
  }, [discount, minAmount]);

  useEffect(() => {
    calcDiscount(promo.type, interests, promo.amount);
  }, [interests]);

  return (
    <div className="PromoCode">
      <span
        className="PromoCode-link"
        role="button"
        tabIndex="0"
        onClick={handleOpen}
        data-testid="promo-link"
      >
        {discount > 0 ? (
          <>
            <CancelIcon className="PromoCode-icon" />
            <span>código promocional: PROMO -{discount}€ dto.</span>
          </>
        ) : (
          linkText
        )}
      </span>
      {opened && (
        <div className="PromoCode-inputBox">
          <div className="PromoCode-input">
            <div className={classnames('Form-group', { 'has-error': !!errorMsg })}>
              <label htmlFor="promo_code" className="Form-label">
                Código Promocional
              </label>
              <input
                id="promo_code"
                name="promo_code"
                data-testid="promo-code"
                type="text"
                tabIndex="0"
                className="Form-field"
                value={promoCode}
                onChange={({ target: { value } }) => setPromoCode(value)}
              />
              {errorMsg && <p className="Form-error">{errorMsg}</p>}
            </div>
          </div>
          <Button
            name="validate_promo_code"
            data-testid="promo-button"
            size="small"
            submit
            primary
            onClick={() => {
              handleValidate(promoCode);
            }}
          >
            Validar
          </Button>
        </div>
      )}
    </div>
  );
};

PromoCode.propTypes = {
  linkText: PropTypes.string.isRequired,
  interests: PropTypes.number.isRequired,
  onApplyPromo: PropTypes.func.isRequired,
};

export default PromoCode;
