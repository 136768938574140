import React, { useState } from 'react';
import { CloseIcon } from '../../skin';
import './Discount.scss';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withWidth } from '@material-ui/core';
import DiscountQuestions from './components/DiscountQuestions';
import DiscountCode from './components/DiscountCode';

const steps = {
  QUESTIONS: 'QUESTIONS',
  CODE: 'CODE',
};
const DiscountPopup = (props) => {
  const { visible, setVisible, width } = props;
  const [step, setStep] = useState(steps.QUESTIONS);

  const handleSubmit = () => {
    setStep(steps.CODE);
  };

  const renderStep = () => {
    switch (step) {
      case steps.QUESTIONS:
        return <DiscountQuestions handleSubmit={handleSubmit} />;
      case steps.CODE:
        return <DiscountCode handleClose={setVisible} />;
      default:
        return <></>;
    }
  };

  return (
    <Dialog fullScreen={width === 'xs'} open={visible} keepMounted maxWidth={'md'}>
      <DialogTitle>
        <div className="Popup-close">
          <button
            onClick={() => {
              setVisible(false);
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <p className="Popup-title">Consigue un 5% de descuento INMEDIATO EN TU PRÉSTAMO </p>
      </DialogTitle>
      <DialogContent>{renderStep()}</DialogContent>
    </Dialog>
  );
};

export default withWidth()(DiscountPopup);
