import { useState, useEffect } from 'react';
import * as apiService from '../api.service';

export default function useEkomiRating() {
  const [ekomiRating, setEkomiRating] = useState({ numberOfRatings: 2200, averageRate: 9.4 });

  useEffect(() => {
    const getEkomiRatings = async () => {
      const { data } = await apiService.getEkomiFeedback();
      if (data) {
        setEkomiRating({
          numberOfRatings: data.number_of_ratings,
          averageRate: parseFloat(data.rounded_average * 2).toFixed(1),
        });
      }
    };
    getEkomiRatings();
  }, []);

  return ekomiRating;
}
