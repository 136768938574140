import { setCookie } from './libs/cookies';
import * as apiService from './api.service';

const IDENTIFIERS = ['at_gd'];

export const createPixel = () => {
  try {
    const params = getQueryParams();
    params['referrer'] = document.referrer?.substr(0, 255);

    const { utm_campaign, utm_medium, utm_source } = params;
    if (!utm_campaign || !utm_medium || !utm_source) {
      return;
    }
    Object.keys(params).forEach((key) => {
      if (isIdentifier(key)) {
        setCookie('pxls_request_id', params[key], { expires: 3 });
      } else {
        setCookie(`pxls_${key.toLowerCase()}`, params[key].toLowerCase(), { expires: 3 });
      }
    });
    apiService.createPixel(params);
  } catch (e) {
    console.error(e);
  }
};

const isIdentifier = (key) => {
  return key && IDENTIFIERS.indexOf(key.toLowerCase()) !== -1;
};

const getQueryParams = () =>
  window.location.search
    .replace('?', '')
    .split('&')
    .reduce((r, e) => ((r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1])), r), {});
